import { PanelWrapper } from './StandardPanel.styled';
import { TPanel } from './StandardPanel.types';

export const StandardPanel = ({
  size = 'lg',
  children,
  bgcolor = 'white',
  border,
}: TPanel) => {
  return (
    <PanelWrapper bgcolor={bgcolor} size={size} border={border}>
      {children}
    </PanelWrapper>
  );
};

export default StandardPanel;
