// relative Imports
import { ModalBodyContainer } from '../Modal.styled';
import * as types from '../Modal.types';

/**
 * Represents a component of a modal dialog that manages the main content area, including optional features such as a close button
 * without a header, and the ability to specify headers and footers within the modal layout. This component is responsible for rendering
 * the children elements and other UI elements based on the provided props, maintaining a consistent design and layout according to the modal size.
 *
 * ## Example Usage
 *
 * ```jsx
 * <ModalBody hasHeader={true} hasFooter={true} size="large">
 *   <p>This is the main content of the modal</p>
 * </ModalBody>
 * ```
 *
 * @param {types.IModalBodyProps} props - The properties passed to the ModalBody component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal body.
 * @param {boolean} [props.hasHeader=false] - Indicates whether a header is present, affecting top padding or spacing.
 * @param {boolean} [props.hasFooter=false] - Indicates whether a footer is present, affecting bottom padding or spacing.
 * @param {types.Size} props.size - The size of the modal body, which may affect its internal styling or layout.
 * @returns {JSX.Element} A ModalBodyContainer element that wraps the content and any additional UI components like a close button.
 */


const ModalBody = (props: types.IModalBodyProps) => {
  const {
    children,
    hasHeader = false,
    hasFooter = false,
    size,
  } = props;
  return (
    <ModalBodyContainer hasHeader={hasHeader} hasFooter={hasFooter} size={size}>
      {children}
    </ModalBodyContainer>
  );
};

export default ModalBody;
