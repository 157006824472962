import { ImageGridImagesProps } from '../../../atoms/imageGrid/ImageGrid.types';
import { Gallery } from '../../../molecules';
import { GalleryType } from './CardGallery.config';
import { getGalleryConfig } from './CardGallery.config';
import { ReactNode } from 'react';

type CustomCardGalleryType = {
  props: CardGalleryUIType;
};

export type CardGalleryUIType = {
  children: ReactNode[];
  slots?: {
    bottomLeft?: ReactNode;
    bottomRight?: ReactNode;
    topLeft?: ReactNode;
    topRight?: ReactNode;
  };
  hasIndicator: boolean;
  imageGridImages?: ImageGridImagesProps[];
  hoverInteraction: boolean;
  hasSlideAnimation: boolean;
  galleryType: GalleryType;
  forceMobileView?: boolean;
  onChange?: (index: number) => number;
};

export const CustomCardGallery = (galleryProps: CustomCardGalleryType) => {
  const {
    children,
    slots,
    hasIndicator = true,
    imageGridImages,
    hoverInteraction = true,
    hasSlideAnimation = true,
    galleryType,
    forceMobileView,
    onChange,
  } = galleryProps.props;

  const galleryConfig = getGalleryConfig(galleryType);

  return (
    <Gallery
      onChange={onChange}
      imageGridImages={imageGridImages}
      visibleGridImagesCount={galleryConfig?.visibleGridImagesCount}
      imageGridAspectRatioHorizontal={
        galleryConfig?.imageGridAspectRatioHorizontal
      }
      hoverInteraction={hoverInteraction}
      aspectRatio={
        forceMobileView
          ? galleryConfig?.aspectRatioMobile
          : galleryConfig?.aspectRatio
      }
      aspectRatioMobile={galleryConfig?.aspectRatioMobile}
      hasIndicator={hasIndicator}
      hasSlideAnimation={hasSlideAnimation}
      bottomLeftComponent={slots?.bottomLeft ?? null}
      bottomRightComponent={slots?.bottomRight ?? null}
      forceMobileView={forceMobileView}
    >
      {
        children && children.length > 0 ? children : [] //fallback for when BE doesn't provide images
      }
    </Gallery>
  );
};

export default CustomCardGallery;
