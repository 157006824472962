import { Dispatch, useState, forwardRef } from 'react';
import * as Types from '../DateTimePicker.types';

import DatePicker from 'react-datepicker';
import {
  DatePickerWrapper,
  StyledHeader,
  SelectWrapper,
  StyledSelectDropdown,
  MonthAndYear,
  Input,
  InputIcon,
  PrevNextButton,
  DatePickerCSSWrapper,
} from '../DateTimePicker.styled';

export const addSecondsToDate = (date: Date, seconds: number) => {
  date.setSeconds(date.getSeconds() + seconds);
  return date;
};

export const DateTimePicker = forwardRef<
  HTMLInputElement,
  Types.TDateTimePicker
>((props: Types.TDateTimePicker, ref) => {
  const {
    disabled,
    selectedDate,
    startDate,
    endDate,
    placeholderText = 'choose a date',
    options,
    hasError,
    yearsConfig = { forwards: 5, backwards: 5 },
    onChange,
    onBlur,
    ...rest
  } = props;

  const [date, setDate]: [Date | undefined, Dispatch<Date | undefined>] =
    useState(selectedDate);

  const {
    minDateEpochSecondsOffset,
    maxDateEpochSecondsOffset,
    showDropdown = false,
    mode = 'date',
  } = options || {};

  const minDate =
    minDateEpochSecondsOffset || minDateEpochSecondsOffset === 0
      ? addSecondsToDate(new Date(), minDateEpochSecondsOffset)
      : undefined;

  const maxDate =
    maxDateEpochSecondsOffset || maxDateEpochSecondsOffset === 0
      ? addSecondsToDate(new Date(), maxDateEpochSecondsOffset)
      : undefined;

  const minMonthPickerDate =
    minDate && new Date(minDate?.getFullYear(), minDate?.getMonth());

  const maxMonthPickerDate =
    maxDate && new Date(maxDate?.getFullYear(), maxDate?.getMonth());

  const generateYears = () => {
    const currentYear = new Date().getFullYear();

    const setForwardsYears = Array.from(
      { length: yearsConfig?.forwards || 0 },
      (_, i) => currentYear + i + 1
    );

    const setBackwardsYears = Array.from(
      { length: yearsConfig?.backwards || 0 },
      (_, i) => currentYear - i - 1
    ).reverse();

    return [...setBackwardsYears, currentYear, ...setForwardsYears];
  };

  const setMonths = Array.from({ length: 12 }, (_, index) => index).map((key) =>
    new Date(0, key as number)
      .toLocaleString('en', { month: 'long' })
      .substring(0, 3)
  );

  const handleChange = (date: Types.TDateTimePickerOnChange) => {
    setDate(date);
    onChange && onChange(date);
  };

  return (
    <DatePickerCSSWrapper>
      <DatePickerWrapper mode={mode} showDropdown={showDropdown}>
        <DatePicker
          showFourColumnMonthYearPicker
          minDate={mode === 'month' ? minMonthPickerDate : minDate}
          maxDate={mode === 'month' ? maxMonthPickerDate : maxDate}
          selected={date}
          startDate={startDate || null}
          endDate={endDate || null}
          onChange={(date: Date) => handleChange(date)}
          onBlur={onBlur}
          formatWeekDay={(day: string) => <>{day.toString().substring(0, 1)}</>}
          showTimeSelect={mode === 'time' || mode === 'datetime'}
          showMonthYearPicker={mode === 'month'}
          showTimeSelectOnly={mode === 'time'}
          placeholderText={placeholderText}
          disabled={disabled}
          autoComplete="off"
          dateFormat={
            mode === 'date'
              ? 'dd/MM/yyyy'
              : mode === 'month'
              ? 'MM/yyyy'
              : mode === 'time'
              ? 'h:mm aa'
              : mode === 'datetime'
              ? 'dd/MM/yyyy h:mm aa'
              : 'dd/MM/yyyy'
          }
          customInput={
            <Input
              ref={ref}
              type={'text'}
              hasError={hasError}
              data-testid="datetime-picker-input"
              disabled={disabled}
              hasValue={date ? true : false}
            />
          }
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            changeMonth,
            increaseYear,
            decreaseYear,
            changeYear,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <StyledHeader mode={mode} showDropdown={showDropdown}>
              <PrevNextButton
                type="button"
                iconType={'PREV'}
                mode={mode}
                onClick={mode === 'month' ? decreaseYear : decreaseMonth}
                disabled={
                  mode === 'month'
                    ? prevYearButtonDisabled
                    : prevMonthButtonDisabled
                }
              />
              {showDropdown ? (
                <SelectWrapper>
                  {mode !== 'month' && (
                    <StyledSelectDropdown
                      value={setMonths[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(setMonths.indexOf(value))
                      }
                    >
                      {setMonths.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </StyledSelectDropdown>
                  )}
                  <StyledSelectDropdown
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) =>
                      changeYear(parseInt(value))
                    }
                  >
                    {generateYears().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </StyledSelectDropdown>
                </SelectWrapper>
              ) : (
                <MonthAndYear>
                  {new Intl.DateTimeFormat('en-IE', {
                    ...(mode !== 'month' && { month: 'long' }),
                    year: 'numeric',
                  }).format(date)}
                </MonthAndYear>
              )}
              <PrevNextButton
                type="button"
                iconType={'NEXT'}
                mode={mode}
                onClick={mode === 'month' ? increaseYear : increaseMonth}
                disabled={
                  mode === 'month'
                    ? nextYearButtonDisabled
                    : nextMonthButtonDisabled
                }
              />
            </StyledHeader>
          )}
          {...rest}
        />
        {date && <InputIcon onClick={() => date && handleChange(undefined)} />}
      </DatePickerWrapper>
    </DatePickerCSSWrapper>
  );
});

export default DateTimePicker;
