import {
  Badge,
  BadgeSize,
  BadgeStyle,
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  Spinner,
  Text,
  TextFontWeight,
  TextSize,
} from '../../atoms';
import * as Styled from './Thumbnail.styled';
import { ThumbnailProps } from './Thumbnail.types';
import Image from 'next/image';

export const Thumbnail = ({
  img,
  cover,
  handleEdit,
  handleDelete,
  handleOverlay,
  overlay,
  error,
  errorMessage,
  loading,
  percentage,
  classNames,
  altText,
}: ThumbnailProps) => {
  return (
    <Styled.Wrapper className={classNames} data-testid="thumbnail">
      {overlay && (
        <Styled.Overlay onClick={handleOverlay}>
          <Icon icon="PLAYFILLED" size={24} />
        </Styled.Overlay>
      )}
      {error && (
        <Styled.Error>
          <Icon icon="WARNINGCIRCLE" size={16} />
          <Text size={TextSize.S12} fontWeight={TextFontWeight.SEMIBOLD} as="p">
            {errorMessage}
          </Text>
        </Styled.Error>
      )}
      {loading && (
        <Styled.Loading>
          <Spinner colour="blue" size="small" />
          {percentage && (
            <Text
              size={TextSize.S12}
              fontWeight={TextFontWeight.SEMIBOLD}
              as="p"
            >
              {percentage}%
            </Text>
          )}
        </Styled.Loading>
      )}
      {cover && (
        <Styled.BadgeWrapper>
          <Badge badgeStyle={BadgeStyle.OVERLAY} badgeSize={BadgeSize.MEDIUM} >Cover</Badge>
        </Styled.BadgeWrapper>
      )}
      {img && (
        <Image src={img} alt={altText} width={200} height={200} priority />
      )}
      <Styled.ButtonWrapper>
        {handleEdit && (
          <Button
            testId="thumbnail-edit"
            Icon={<Icon icon="PEN" size={16} />}
            buttonType={ButtonTypes.TERTIARY}
            onClick={handleEdit}
            buttonSize={ButtonSizes.X_SMALL}
          ></Button>
        )}
        {handleDelete && (
          <Button
            testId="thumbnail-delete"
            Icon={<Icon icon="TRASH" size={16} />}
            buttonType={ButtonTypes.TERTIARY}
            onClick={handleDelete}
            buttonSize={ButtonSizes.X_SMALL}
          ></Button>
        )}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

export default Thumbnail;
