import { ReactNode } from 'react';
import { Icon, Text, TextFontWeight, TextSize } from '../../../atoms';

export const determineBottomSlotContent = (
  displayTextAndIcon: boolean,
  displayText: string,
): ReactNode => {
  if (displayTextAndIcon) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          gap: '4px',
          color: '#999999',
        }}
      >
        <Icon icon="SUCCESSCIRCLEFILLED" size={12} />
        <Text
          size={TextSize.S10}
          fontWeight={TextFontWeight.SEMIBOLD}
          textColor="#999999"
          data-tracking="srp_advantage_badge"
        >
          ADVANTAGE
        </Text>
      </div>
    );
  }
  if (displayText) {
    return (
      <Text
        size={TextSize.S10}
        fontWeight={TextFontWeight.SEMIBOLD}
        textColor="#999999"
        data-tracking="agent_name"
      >
        {displayText}
      </Text>
    );
  }
  return null;
};
export default determineBottomSlotContent;
