import { IconProps } from './Icons.types';
import { ICONS } from './Icons';
import { IconWrapper } from './Icon.styled';

export function Icon({ icon, size }: IconProps) {
  return (
    <IconWrapper size={size} aria-hidden={true}>
      {ICONS[icon]}
    </IconWrapper>
  );
}

export default Icon;
