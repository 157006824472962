import Image, { ImageLoader, ImageProps } from 'next/image';

/**
 * This is a custom loader for the next/image component
 * In order for us to be able to load external images from the CDN we need to ensure
 * the image is loaded from the correct URL, the NEXT_PUBLIC_BASE_CDN_URL env variable
 */
const customLoader: ImageLoader = ({ src, width, quality = 75 }) => {
  return `${process.env['NEXT_PUBLIC_BASE_CDN_URL']}/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality}`;
};

const ImageWrapper = (props: ImageProps) => {
  return <Image loader={customLoader} {...props} />;
};

export default ImageWrapper;
