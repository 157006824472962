import type { ButtonHTMLAttributes, ReactNode, ReactElement } from 'react';

export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  GHOST = 'GHOST',
}

export enum ButtonSizes {
  X_SMALL = 'X_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type ButtonStyleProps = {
  buttonSize?: string;
  buttonType?: string;
  fullWidth?: boolean;
  isButtonGroup?: boolean;
  isLinkButtonGroup?: boolean;
  Icon?: string;
  position?: 'left' | 'right';
  loader?: boolean;
  active?: boolean;
  hasChildren?: ReactNode;
  isIconOnly?: boolean;
  isMobile?: boolean;
};

export type ButtonProps = {
  children?: ReactNode;
  buttonType?: ButtonTypes;
  buttonSize?: ButtonSizes;
  'data-testid'?: string;
  'data-tracking'?: string;
  fullWidth?: boolean;
  id?: string;
  href?: string;
  classNames?: string;
  disabled?: boolean;
  active?: boolean;
  element?: React.ElementType;
  testId?: string;
  isButtonGroup?: boolean;
  isLinkButtonGroup?: boolean;
  position?: 'left' | 'right';
  Icon?: ReactElement;
  loader?: boolean;
  fillIcon?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
} & ButtonHTMLAttributes<HTMLButtonElement>;
