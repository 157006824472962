import { useEffect, useState } from 'react';
import { urlObject } from './VideoUploader.types';
export const useVideoUploader = (
  type: string,
  onChange: (urlValue: urlObject) => void,
  initialValue?: string,
) => {
  const [reconstructedValue, setReconstructedValue] = useState('');
  const [img, setImg] = useState('');
  const [error, setError] = useState(false);
  const [id, setId] = useState<string | null>('');
  const [platform, setPlatform] = useState('');
  const [loading, setLoading] = useState(false);

  const videoList = ['youtube', 'vimeo'];
  const virtualTourList = [
    'matterport',
    'eyespy360',
    'giraffe360',
    'my360',
    'three_d_designbureau',
    'cloudpano',
    'modelworks',
  ];

  const reconstructURL = (type: string, initialValue: any) => {
    if (type === 'video') {
      return initialValue;
    } else if (type === 'virtualTour' && initialValue?.provider) {
      const providers: { [platform: string]: string } = {
        matterport: `https://my.matterport.com/show/?m=${initialValue.externalId}`,
        my360: `https://vt.plushglobalmedia.com/tour/${initialValue.externalId}`,
        three_d_designbureau: `https://www.3ddesignbureau.com/360VT/${initialValue.externalId}`,
        modelworks: `https://apps.modelworks.ie/${initialValue.externalId}/index.htm`,
      };

      return providers[initialValue.provider] || initialValue.externalId;
    }
    return '';
  };

  const [stringValue, setStringValue] = useState(
    reconstructURL(type, initialValue),
  );

  const setInputValue = (inputValue: string) => {
    setStringValue(inputValue);
    !inputValue &&
      onChange({
        provider: undefined,
        externalId: undefined,
        original: undefined,
      });
    error && setError(false);
  };

  const matchRegex = (url: string, regex: RegExp, returnId: boolean) => {
    try {
      const match = url.match(regex);
      if (match) {
        return returnId ? match[1] : match[0];
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const validateYouTubeURL = (url: string) => {
    // Standard YouTube URL
    const standardRegex =
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_=&?-]+?)(&feature=youtu.be)?$/;
    const standardResult = matchRegex(url, standardRegex, true);

    // Short code YouTube URL
    const shortCodeRegex =
      /^(?:https?:\/\/)?youtu\.be\/([a-zA-Z0-9_-]+)(?:[&?](?:t=[0-9]+|feature=[a-zA-Z0-9_-]+))*$/;
    const shortCodeResult = matchRegex(url, shortCodeRegex, true);

    return standardResult !== null ? standardResult : shortCodeResult;
  };

  const validateVimeoURL = (url: string) => {
    // Standard Vimeo URL
    const standardRegex =
      /(^(http|https)?:\/\/(www\.)?vimeo\.com\/[A-Za-z0-9_-]+\/(?:[A-Za-z0-9_-]+)?(?<!\/)\/?)$/;
    const standardResult = matchRegex(url, standardRegex, true);

    // Vimeo Shortcode URL
    const shortCodeRegex =
      /^(?:http|https)?:\/\/(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|video\/|)(\d+)(?:\/\?)?/;
    const shortCodeResult = matchRegex(url, shortCodeRegex, true);

    return standardResult !== null ? standardResult : shortCodeResult;
  };

  const urlConfig: {
    [provider: string]: { regex: RegExp; returnId: boolean };
  } = {
    matterport: {
      regex: /https:\/\/my\.matterport\.com\/show\/\?m=([a-zA-Z0-9]{11})(&|$)/,
      returnId: true,
    },
    eyespy360: {
      regex: /^https:\/\/[^/.]+.vr-360-tour.com\/[\w\W]+/,
      returnId: false,
    },
    giraffe360: {
      regex:
        /^https:\/\/(premium|tour)\.giraffe360\.com\/(([\w\W]+\/)*[a-zA-Z0-9/]+)/,
      returnId: false,
    },
    my360: {
      regex: /^https:\/\/vt\.plushglobalmedia\.com\/tour\/([a-zA-Z0-9]+)/,
      returnId: true,
    },
    three_d_designbureau: {
      regex:
        /^https:\/\/(?:www\.)?3ddesignbureau\.com\/360VT\/([a-zA-Z0-9]+)(?:\/)?/,
      returnId: true,
    },
    cloudpano: {
      regex: /^https:\/\/app\.cloudpano\.com\/tours\/([^?/]+)/,
      returnId: false,
    },
    modelworks: {
      regex: /^https:\/\/apps\.modelworks.ie\/(.+)\/index.htm/,
      returnId: true,
    },
  };

  const validateURL = (url: string, provider: string) => {
    const { regex, returnId } = urlConfig[provider];
    const regexObj = new RegExp(regex);
    return matchRegex(url, regexObj, returnId);
  };

  const getYouTubeVideoThumbnailFromId = (videoId: string | null) => {
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    }
    return '';
  };

  const getVimeoVideoThumbnailFromId = async (videoId: string | null) => {
    try {
      if (!videoId) {
        return null;
      }
      const response = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`,
      );
      const videoData = await response.json();
      if (videoData.thumbnail_url) {
        return videoData.thumbnail_url;
      } else {
        return '/static/images/placeAd/preview/vimeo.jpg';
      }
    } catch (error) {
      return null;
    }
  };

  const validateVideoURL = (
    url: string,
  ): { [platform: string]: string | null } => {
    const validationResults = [
      ...virtualTourList.map((platform) => validateURL(url, platform)),
      validateYouTubeURL(url),
      validateVimeoURL(url),
    ];
    return Object.fromEntries(
      [...virtualTourList, ...videoList].map((platform, index) => [
        platform,
        validationResults[index],
      ]),
    );
  };

  const getImgFromId = (
    validList: { [platform: string]: string | null },
    id: string | null,
  ) => {
    const platform =
      Object.keys(validList).find((key) => validList[key] !== null) || '';
    if (!platform) return '';
    if (platform === 'youtube') {
      return getYouTubeVideoThumbnailFromId(id);
    } else if (platform === 'vimeo') {
      return getVimeoVideoThumbnailFromId(id);
    } else {
      return `/static/images/placeAd/preview/${platform}.jpg`;
    }
  };

  const handleFind = async () => {
    setLoading(true);

    const valid = validateVideoURL(stringValue);

    const foundKey =
      Object.keys(valid).find((key) => valid[key] !== null) || '';

    const typeMatch = Boolean(
      (type === 'virtualTour' && virtualTourList.includes(foundKey)) ||
        (type === 'video' && videoList.includes(foundKey)),
    );

    if (foundKey && typeMatch) {
      setError(false);
      const platform = foundKey;
      const id =
        Object.entries(valid).find(([, value]) => value !== null)?.[1] || '';

      setId(id);
      setPlatform(platform);

      onChange({
        provider: platform,
        externalId: id,
        original: stringValue,
      });

      const img = await getImgFromId(valid, id);
      setImg(img);
    } else {
      setError(true);
      setId('');
    }

    setLoading(false);
  };

  const onClickRemove = () => {
    setId('');
    setStringValue('');
    onChange({ provider: '', externalId: '', original: '' });
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  useEffect(() => {
    setId('');
  }, [stringValue]);

  useEffect(() => {
    if (initialValue) {
      const reconstructedValue = reconstructURL(type, initialValue);
      setReconstructedValue(reconstructedValue);
      setStringValue(reconstructedValue);
    }
  }, [initialValue, type]);

  useEffect(() => {
    if (reconstructedValue) {
      handleFind();
    }
  }, [reconstructedValue]);

  return {
    openInNewTab,
    onClickRemove,
    handleFind,
    setInputValue,
    setStringValue,
    setError,
    img,
    error,
    loading,
    id,
    platform,
    reconstructedValue,
    stringValue,
  };
};
