import styled from 'styled-components';
import { Pagination as UnityPagination } from '@dsch/pagination';

export const StyledPagination = styled(UnityPagination)`
  font-size: 14px;

  & ::selection {
    color: inherit;
    background: none;
  }

  @media only screen and (max-width: 600px) {
    .hide {
      display: none;
    }
  }
`;

export const StyledBreak = styled.span`
  display: none;
  @media only screen and (min-width: 600px) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: ${({ theme }) => theme.toolkit.spacing.M32};
    width: 42px;
    border: 1px solid ${({ theme }) => theme.toolkit.colours.GREY.LIGHT};
    background: #ffffff;
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
    cursor: default;
    margin: 0 ${({ theme }) => theme.toolkit.spacing.M24};
  }
`;
