import { useMemo } from 'react';
import {
  AdFeatureLevelFull,
  ImageSizes,
  ListingsImageSize,
  Photo,
  SearchPropertyCard,
} from '../../../types';

import {
  CardBannerPropsType,
  CardContentPropsType,
  CardGalleryPropsType,
  CardLayoutPropsType,
} from '../services/srpCard.types';
import { SrpCardSubUnitPropsType } from '../services/propertyUnits.types';

// The number of cards to disable lazy loading on
const EAGERLY_LOAD_CARDS = 1;

/**
 * Hook to get the listing sent from BE via API,
 * return an array with only the necessary data
 * for the cards in the SRP
 * @returns {AdditionalParams[]} Array of objects with the necessary data for the cards
 */
export const useListingParams = ({
  listings,
  isMobile,
  isMapView,
}: {
  listings: SearchPropertyCard[];
  isMobile: boolean;
  isMapView?: boolean;
}) => {
  const filteredListings = useMemo(() => {
    return processListings(listings, isMobile, isMapView);
  }, [listings, isMobile, isMapView]);

  return {
    filteredListings,
    getImagesWithAltBySize,
    handleDeprecatedFeaturedLevels,
  };
};

export const processListings = (
  listings: SearchPropertyCard[],
  isMobile: boolean,
  isMapView: boolean,
) => {
  if (!listings || listings.length === 0) {
    return [];
  }

  return listings.map((response, index) => {
    const listing = response?.listing ?? null;
    const prs = listing?.prs ?? null;
    const newHome = listing?.newHome ?? null;
    const seller = listing?.seller ?? null;
    const images = listing?.media?.images;

    const parsedFeaturedLevelFull = handleDeprecatedFeaturedLevels(
      listing?.featuredLevelFull,
    );

    const size720x480Images = getImagesWithAltBySize({
      images: images,
      alt: listing?.title,
      size: ImageSizes.size720x480,
    });

    const cardBannerProps: CardBannerPropsType = seller && {
      profileImage: seller.profileImage,
      profileImageAlt: 'Negotiator Image',
      sellerType: seller.sellerType,
      sellerName: seller.name,
      sellerBranch: seller.branch,
      standardLogo: listing.pageBranding?.standardLogo,
      backgroundColor: listing.pageBranding?.backgroundColour,
    };

    const cardContentProps: CardContentPropsType = {
      adTitle: listing?.title,
      adCategory: listing?.category,
      tagline: listing?.prs?.tagLine ?? listing?.newHome?.tagLine,
      price: listing?.price,
      numBedrooms: listing?.numBedrooms,
      numBathrooms: listing?.numBathrooms,
      propertySize: listing?.propertySize,
      propertyType: listing?.propertyType,
      bathroomType: listing?.bathroomType,
      propertyTypeExtraInfo: listing?.propertyTypeExtraInfo,
      berRating: listing?.ber,
      prsAd: prs,
      newHomeAd: newHome,
      viewings: listing?.openViewings?.summary,
      sticker: listing?.sticker,
      agentBranch: seller?.branch,
      premierPartner: listing?.premierPartner,
      savedAd: response?.savedAd,
    };

    const cardGalleryProps: CardGalleryPropsType = {
      images: size720x480Images,
      offers: listing?.offers,
      label: listing?.label,
      squareLogoAlt: `${seller?.name ?? 'Estate agent'} at Daft.ie`,
      squareLogo: listing?.pageBranding?.squareLogos
        ? listing?.pageBranding?.squareLogos[0]
        : undefined,
      is_PRS_NH: Boolean(prs || newHome),
      isLoadEagerly: index < EAGERLY_LOAD_CARDS,
      isMapView: isMapView,
      totalImages: listing?.media?.totalImages,
    };

    const findSubUnits = [
      ...(listing?.newHome?.subUnits ?? []),
      ...(listing?.prs?.subUnits ?? []),
    ];

    const cardSubunitPropsArray: SrpCardSubUnitPropsType[] = findSubUnits.map(
      (subUnit, index) => {
        const subunitImage = subUnit?.media?.images;
        const size72x52Images = getImagesWithAltBySize({
          images: subunitImage,
          alt: subUnit?.category,
          size: 'size72x52',
        });

        return {
          image: size72x52Images[0],
          price: subUnit.price,
          numBedrooms: subUnit.numBedrooms,
          numBathrooms: subUnit.numBathrooms,
          propertyType: subUnit.propertyType,
          url: subUnit.seoFriendlyPath,
          isLoadEagerly: index < EAGERLY_LOAD_CARDS,
        };
      },
    );

    const cardLayoutProps: CardLayoutPropsType = {
      isMobile: isMobile,
      isMapView: isMapView,
    };

    const additionalProps = {
      id: listing?.id,
      featuredLevelFull: parsedFeaturedLevelFull,
      seoFriendlyPath: listing?.seoFriendlyPath,
      cardBannerProps,
      cardContentProps,
      cardGalleryProps,
      cardLayoutProps,
      cardSubunitsProps: cardSubunitPropsArray,
    };

    return additionalProps;
  });
};

/**
 * Support for existing ads with deprecated featured levels
 *
 */
export const handleDeprecatedFeaturedLevels = (featuredLevelFull: any) => {
  switch (featuredLevelFull) {
    case 'BASIC':
      return AdFeatureLevelFull.STANDARD;
    case 'PREMIER_PARTNER_FEATURED_PLUS':
      return AdFeatureLevelFull.PREMIER_PARTNER_FEATURED;
    case 'FEATURED_PLUS':
      return AdFeatureLevelFull.FEATURED;
    default:
      return featuredLevelFull;
  }
};

/**
 * Returns an array of image src of given size
 * Appends the alt text
 */
export const getImagesWithAltBySize = ({
  images,
  size,
  alt,
}: {
  images: Photo[];
  size: ListingsImageSize;
  alt: string;
}) => {
  if (!images) return [];

  return images
    .filter((image) => image[size])
    .map((image) => ({
      src: image[size],
      alt: alt ?? 'Image',
    }));
};
