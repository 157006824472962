import styled from 'styled-components';
import {
  MaskedContainerShape,
  MaskedContainerWrapperProps,
} from './MaskedContainer.types';

export const MaskedContainer = styled.div<MaskedContainerWrapperProps>`
  border-radius: ${({ shape }) =>
    shape === MaskedContainerShape.CIRCLE ? '50%' : '4px'};
  border: ${({ outline }) => (outline ? '1px solid #fff' : 'none')};
  box-sizing: border-box;
  height: ${({ size }) => `${size}px`};
  overflow: hidden;
  position: relative;
  width: ${({ size }) => `${size}px`};
  z-index: 1;

  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    height: ${({ mobileSize, size }) =>
      mobileSize ? `${mobileSize}px` : `${size}px`};
    width: ${({ mobileSize, size }) =>
      mobileSize ? `${mobileSize}px` : `${size}px`};
  }
`;
