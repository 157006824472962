import styled from 'styled-components';

export const Map = styled.div<{ mapEnabled: boolean }>`
  position: relative;
  ${({ mapEnabled }) =>
    mapEnabled
      ? ''
      : `
    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 10;
    }
  `}
  > div {
    width: 100% !important;
    height: 280px !important;
    max-height: 80vh !important;
    @media only screen and (min-width: 652px) {
      height: 360px !important;
    }
  }
`;

export const ControlWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
  bottom: ${({ theme }) => theme.toolkit.spacing.L40};
  right: ${({ theme }) => theme.toolkit.spacing.M16};
`;

export const MapIcon = styled.div<{ draggable: boolean }>`
  width: ${({ theme }) => theme.toolkit.spacing.M32};
  height: ${({ theme }) => theme.toolkit.spacing.M32};
  ${({ theme, draggable }) =>
    draggable &&
    `
  .map-pin:hover {
    fill: ${theme.toolkit.brand.primary.DARKER};
  }
  .map-pin:active {
    fill: ${theme.toolkit.brand.primary.LIGHT};
  }
  `}
`;
