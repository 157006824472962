import { useState } from 'react';

export const useGallery = (
  setIndex: (index: number) => void,
  setPreviousIndex: (index: number) => void,
  children: React.ReactNode | React.ReactNode[],
  index: number,
  onChange: (index: number) => void,
) => {
  // set the swipe threshold
  const SWIPE_THRESHOLD = 50;
  const items = Array.isArray(children) ? children : [children];
  const lastIndex = items.length - 1;
  const [loadedGalleryImages, setLoadedGalleryImages] = useState([index || 0]);
  if (!loadedGalleryImages.includes(index)) {
    loadedGalleryImages.push(index);
  }
  const [touchStart, setTouchStart] = useState(0);

  /*
   * We load and display the images in the gallery from the loadedGalleryImages array.
   * When navigating the gallery we preload one value ahead of the direction to smooth
   * the user experience and help avoid waiting for the image to load. as the click through
   */
  const updateLoadedGalleryImages = ({
    loadAheadValue,
    nextIndex,
  }: {
    loadAheadValue: number;
    nextIndex: number;
  }) => {
    const newLoadedImages = [...loadedGalleryImages];

    if (!newLoadedImages.includes(nextIndex)) {
      newLoadedImages.push(nextIndex);
    }

    let nextPreloadImageIndex = nextIndex + loadAheadValue;
    if (nextPreloadImageIndex === -1) {
      nextPreloadImageIndex = items.length - 1;
    }
    if (
      items[nextPreloadImageIndex] &&
      !newLoadedImages.includes(nextPreloadImageIndex)
    ) {
      newLoadedImages.push(nextPreloadImageIndex);
    }

    setLoadedGalleryImages(newLoadedImages);
  };

  const onClickNext = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (index === lastIndex) {
      onChange(0);
      setIndex(0);
      setPreviousIndex(index);
      updateLoadedGalleryImages({ loadAheadValue: +1, nextIndex: 0 });
    } else {
      const nextIndex = index + 1;
      onChange(nextIndex);
      setIndex(nextIndex);
      setPreviousIndex(index);
      updateLoadedGalleryImages({ loadAheadValue: +1, nextIndex });
    }
  };

  const onClickPrev = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (index === 0) {
      onChange(lastIndex);
      setIndex(lastIndex);
      setPreviousIndex(index);
      updateLoadedGalleryImages({ loadAheadValue: -1, nextIndex: lastIndex });
    } else {
      const nextIndex = index - 1;
      onChange(nextIndex);
      setIndex(nextIndex);
      setPreviousIndex(index);
      updateLoadedGalleryImages({ loadAheadValue: -1, nextIndex });
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const touchEnd = e.changedTouches[0].clientX;
    if (touchStart - touchEnd > SWIPE_THRESHOLD) {
      onClickNext();
    } else if (touchEnd - touchStart > SWIPE_THRESHOLD) {
      onClickPrev();
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setTouchStart(e.clientX);
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    const touchEnd = e.clientX;
    if (touchStart - touchEnd > SWIPE_THRESHOLD) {
      onClickNext();
    } else if (touchEnd - touchStart > SWIPE_THRESHOLD) {
      onClickPrev();
    }
  };
  return {
    handleTouchStart,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp,
    loadedGalleryImages,
    onClickNext,
    onClickPrev,
    items,
    updateLoadedGalleryImages,
  };
};
