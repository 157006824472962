import { CardLayout, CardProps } from './Card.types';
import { CardGallery } from './cardComponents/cardGallery/CardGallery';
import { Grid, GridItem } from '../../atoms';
import { CardContent } from './cardComponents/cardContent';

export const Card = ({
  layout = CardLayout.VerticalDesktop,
  cardGallery,
  cardBanner,
  cardContent,
  cardContentLayout,
}: CardProps) => {
  return (
    <Grid columns={12} gridGap={{ xs: 'S0' }}>
      <GridItem startColumn={{ xs: 1, lg: 1 }} spanColumns={{ xs: 12, lg: 12 }}>
        {cardBanner}
      </GridItem>
      {cardGallery && (
        <GridItem
          startColumn={{ xs: 1, lg: 1 }}
          spanColumns={{
            xs: 12,
            lg: layout === CardLayout.HorizontalDesktop ? 6 : 12,
          }}
        >
          <CardGallery>{cardGallery}</CardGallery>
        </GridItem>
      )}
      <GridItem
        startColumn={{ xs: 1, lg: 1 }}
        spanColumns={{
          xs: 12,
          lg: layout === CardLayout.HorizontalDesktop ? 6 : 12,
        }}
      >
        <CardContent.Root layout={layout} cardContentLayout={cardContentLayout}>
          {cardContent}
        </CardContent.Root>
      </GridItem>
    </Grid>
  );
};

export default Card;
