import React from 'react';
import {
  GalleryImageContainer,
  GalleryImageContainerContent,
} from '../SearchPage.styled';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Gallery,
  Icon,
} from '@dist-property-frontend/toolkit';

/**
 * the maximum number of images the gallery will display
 * the index of the image to be blurred and View all photos button to be displayed
 */
const MAX_IMAGES = 8;

/**
 *
 * @param images - the images array
 * @param totalImages - the total number of images
 * @returns the wrapped images array with the blur and extra details if condition is met
 */
export const renderSRPGalleryImages = (
  images: any[],
  totalImages: number,
  imageLoading?: 'eager' | 'lazy',
) => {
  const wrappedImages = images?.map(
    (image: { src: string; alt: string }, index: number) => {
      const hasBlur = checkShouldBlur(totalImages, index);

      return (
        <GalleryImageContainer
          key={'galleryImage' + index}
          data-testid="imageContainer"
        >
          <Gallery.Image
            src={image.src}
            alt={image.alt}
            loading={imageLoading}
            style={
              hasBlur
                ? {
                    backdropFilter: 'blur(6px) brightness(0.56)',
                    filter: 'blur(6px) brightness(0.56)',
                  }
                : {}
            }
          />
          {hasBlur && (
            <GalleryImageContainerContent data-testid="contentContainer">
              <Button
                buttonSize={ButtonSizes.X_SMALL}
                buttonType={ButtonTypes.TERTIARY}
                Icon={<Icon icon="CHEVRONRIGHT" size={16} />}
                position="right"
                data-tracking="srp_view_all_photos"
              >
                View all photos
              </Button>
            </GalleryImageContainerContent>
          )}
        </GalleryImageContainer>
      );
    },
  );

  return wrappedImages;
};

/**
 * Returns the index of the image to be blurred and View all photos button to be displayed
 *
 * If Ad totalImages > MAX_IMAGES , the last image will be blurred and the View all photos button will be displayed
 *
 * If Ad totalImages <= MAX_IMAGES , no image will be blurred and the View all photos button will not be displayed
 *
 */
export const checkShouldBlur = (totalImages: number, index: number) => {
  return totalImages > MAX_IMAGES && index === MAX_IMAGES;
};
