export enum Size {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type TModalStyle = {
  size: Size;
  overlay?: boolean;
  isLargeModalWithFooter?: boolean;
};

export interface IModal extends TModalStyle {
  children: JSX.Element | JSX.Element[];
  testId: string;
  show: boolean;
  onClose: () => void;
}

interface IModalBaseProps {
  size: Size;
  children?: React.ReactNode;
}

export interface IModalHeaderProps extends IModalBaseProps {
  hasCloseButton?: boolean;
  handleCloseClick?: () => void;
}

export type TModalHeaderStyleProps = {
  hasCloseButton: boolean;
  size: Size;
}

export interface IModalFooterProps {
  children: JSX.Element | JSX.Element[];
  size: Size;
}

export type TModalFooterStyleProps = {
  size: Size;
}
export interface IModalBodyProps extends IModalBaseProps {
  hasFooter: boolean;
  hasHeader: boolean;
}

export type TModalBodyStyleProps = {
  hasFooter: boolean;
  hasHeader: boolean;
  size: Size;
}