import styled from 'styled-components';

export const RadioButtonWrapper = styled.label<{
  checked?: boolean;
  disabled?: boolean;
  recommendation?: boolean;
}>`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  touch-action: manipulation;
  width: 100%;
  height: 100%;

  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.mediumOnly}) {
    ${({ recommendation, theme }) =>
      recommendation && `margin-top: ${theme.toolkit.spacing.M24}};`}
  }

  ${({ theme, checked, disabled }) =>
    `background-color: ${
      disabled
        ? theme.toolkit.brand.grey?.LIGHTEST
        : theme.toolkit.brand.grey?.TEXTCONTRAST
    };
      padding: ${theme.toolkit.spacing.M16};
      outline: ${
        checked
          ? `solid ${
              disabled
                ? `0.0625rem ${theme.toolkit.brand.grey?.LIGHTER}`
                : `${theme.toolkit.spacing.S2} ${theme.toolkit.brand.grey?.DARKER}`
            }`
          : `0.0625rem solid ${theme.toolkit.brand.grey?.LIGHTER}`
      };
      border-radius: ${theme.toolkit.spacing.S4};`};
  ${({ recommendation, theme }) =>
    recommendation &&
    `
  ::before {
    content: 'RECOMMENDED';
    left: 50%;
    transform: translate(-50%, 0); 
    position: absolute;
    top: -1.5625rem;
    padding: ${theme.toolkit.spacing.S4} ${theme.toolkit.spacing.S8};
    background-color: ${theme.toolkit.brand.grey?.DARKER};
    color: white;
    border-radius: ${theme.toolkit.spacing.S4} ${theme.toolkit.spacing.S4} 0 0;
    font-weight: ${theme.toolkit.typography.fontWeights.SEMIBOLD};
    ${theme.toolkit.typography.fontSizes.S12};
  }
  `};
`;

export const StyledRadioButton = styled.input<{
  hasError: boolean;
  checked: boolean;
  disabled: boolean;
}>`
  appearance: none;
  -webkit-appearance: none;
  width: ${({ theme }) => theme.toolkit.spacing.M20};
  height: ${({ theme }) => theme.toolkit.spacing.M20};
  cursor: pointer;
  border-radius: 50%;
  align-self: center;
  background: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
  border: 0.0625rem solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.3125rem
      ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  }

  &:checked {
    background: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
    border: ${({ theme }) => theme.toolkit.spacing.S2} solid
      ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    ::before {
      content: '';
      width: ${({ theme }) => theme.toolkit.spacing.S12};
      height: ${({ theme }) => theme.toolkit.spacing.S12};
      border-radius: 50%;
      background-color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
      position: absolute;
      transform: ${({ theme }) =>
        `translate(${theme.toolkit.spacing.S2}, ${theme.toolkit.spacing.S2})`};
      display: block;
    }
  }
`;

export const RadioButtonText = styled.div`
  content: '';
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: ${({ theme }) => theme.toolkit.spacing.S12};
`;

export const RadioButtonLabel = styled.div`
  align-self: center;
  padding-left: ${({ theme }) => theme.toolkit.spacing.S12};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const RadioIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BulletPoint = styled.div`
  content: '';
  display: inline-block;
  width: ${({ theme }) => theme.toolkit.spacing.S4};
  height: ${({ theme }) => theme.toolkit.spacing.S4};
  background-color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.toolkit.spacing.S8};
`;

export const BulletList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
`;

export const AdViewBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.toolkit.spacing.S4};
`;

export const AdViewBar = styled.div<{ color: string | string[] }>`
  height: ${({ theme }) => theme.toolkit.spacing.S4};
  border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
  width: 100%;
  display: flex;
  // this pseudo elements is left here if we still want to have different colors for the left and right side of the bar in the future
  ${({ color, theme }) =>
    typeof color === 'string'
      ? `background-color: ${color};`
      : `::before { 
    content: '';
    flex: 1;
    display: block;
    height: ${theme.toolkit.spacing.S4};
    width: 50%;
    background-color: ${color[0]};
    border-radius: ${theme.toolkit.spacing.S2} 0 0 ${theme.toolkit.spacing.S2};
  }

  ::after {
    content: '';
    flex: 1;
    display: block;
    height: ${theme.toolkit.spacing.S4};
    width: 50%;
    background-color: ${color[1]};
    border-radius: 0 ${theme.toolkit.spacing.S2} ${theme.toolkit.spacing.S2} 0;
    float: right;
  }`}
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
  height: 0.0625rem;
  width: 100%;
  margin-top: auto;
  margin-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
`;

export const DashedSpan = styled.span`
  border-bottom: 0.0625rem dashed #999999;
  ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
  cursor: pointer;
`;

export const RadioContainer = styled.div`
  display: flex;
  margin-left: auto;
`;
