import { useState, useEffect, createRef, Ref, useCallback } from 'react';
import {
  MapRef,
  MarkerDragEvent,
  MapLayerMouseEvent,
} from 'react-map-gl/maplibre';
import { TMapHook } from './Map.types';

export const useMap = (props: TMapHook) => {
  const mapRef: Ref<MapRef> = createRef();

  const [mapEnabled, setMapEnabled] = useState(false);

  const [marker, setMarker] = useState(props.marker);

  const [selected, setSelected] = useState(false);

  const [viewport, setViewport] = useState(props.viewport);

  let currentlyDisabling = false;
  let positionAtTimeOfEnabling = 0;

  useEffect(() => {
    setViewport(props.viewport);
    return () => {
      setMapEnabled(true);
    };
  }, [props.viewport]);

  const checkShouldItDisableMap = () => {
    const currentPosition = window.scrollY;
    /**
     * If the user scrolls by a 150px up or down,
     * then disable the map again
     */
    if (
      currentPosition - positionAtTimeOfEnabling > 150 ||
      currentPosition - positionAtTimeOfEnabling < -150
    ) {
      positionAtTimeOfEnabling = 0;
      disableMap();
    }
  };

  const disableMap = () => {
    if (!currentlyDisabling) {
      currentlyDisabling = true;
      setMapEnabled(() => {
        currentlyDisabling = false;
        window.removeEventListener('scroll', checkShouldItDisableMap, true);
        return false;
      });
    }
  };

  const enableMap = () => {
    positionAtTimeOfEnabling = window.scrollY;
    window.addEventListener('scroll', checkShouldItDisableMap, true);
    setMapEnabled(true);
  };

  const onMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      if (props.draggable) {
        const map = mapRef.current?.getMap();
        setMarker({
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
        });
        map &&
          map.flyTo({
            center: [event.lngLat.lng, event.lngLat.lat],
            duration: 1000,
          });
        setSelected(true);
      }
    },
    [mapRef, props.draggable]
  );

  const handleZoomIn = () => {
    const map = mapRef.current?.getMap();
    map &&
      map.easeTo({
        zoom: viewport.zoom + 1,
        duration: 500,
      });
    setViewport({ ...viewport, zoom: viewport.zoom + 1 });
  };

  const handleZoomOut = () => {
    const map = mapRef.current?.getMap();
    map &&
      map.easeTo({
        zoom: viewport.zoom - 1,
        duration: 500,
      });
    setViewport({ ...viewport, zoom: viewport.zoom - 1 });
  };

  const onMarkerDrag = useCallback(
    (event: MarkerDragEvent) => {
      if (props.draggable)
        setMarker({
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
        });
    },
    [props.draggable]
  );

  const onMarkerDragEnd = useCallback(
    (event: MarkerDragEvent) => {
      if (props.draggable) {
        const map = mapRef.current?.getMap();
        setViewport({
          ...viewport,
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
        });
        map &&
          map.flyTo({
            center: [event.lngLat.lng, event.lngLat.lat],
            duration: 1000,
          });
        setSelected(true);
      }
    },
    [mapRef, viewport, props.draggable]
  );
  return {
    mapRef,
    viewport,
    marker,
    mapEnabled,
    selected,
    enableMap,
    onMapClick,
    handleZoomIn,
    handleZoomOut,
    onMarkerDrag,
    onMarkerDragEnd,
  };
};
