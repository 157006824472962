import styled, { DefaultTheme } from 'styled-components';
import { TPanelStyled } from './StandardPanel.types';

const getBg = (theme: DefaultTheme, bgcolor: string | undefined) => {
  switch (bgcolor) {
    case 'blue':
      return `background: ${theme.toolkit.brand.primary.LIGHTEST};`;
    case 'grey':
      return `background: ${theme.toolkit.brand.grey?.LIGHTEST};`;
    case 'green':
      return `background: ${theme.toolkit.brand.success.LIGHTEST};`;
    case 'white':
      return `background: white;`;
    default:
      return;
  }
};

const getSize = (theme: DefaultTheme, size: string | undefined) => {
  switch (size) {
    case 'lg':
      return `padding: ${theme.toolkit.spacing.M24}; @media ${theme.toolkit.mediaQuery.small} {
        padding:${theme.toolkit.spacing.M32};
      }`;
    case 'md':
      return `padding: ${theme.toolkit.spacing.M16}; @media ${theme.toolkit.mediaQuery.small} {
        padding:${theme.toolkit.spacing.M24};
      }`;
    case 'sm':
      return `padding: ${theme.toolkit.spacing.M16};`;
    default:
      return;
  }
};

export const PanelWrapper = styled.div<TPanelStyled>`
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  width: 100%;
  height: 100%;
  ${({ bgcolor, theme }) => getBg(theme, bgcolor)};
  ${({ size, theme }) => getSize(theme, size)};
  ${({ border, theme }) =>
    border && `border: 1px solid ${theme.toolkit.brand.grey?.LIGHTER}`};
`;
